"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.oauthSignIn = exports.store = exports.signInWithRedirect = void 0;
const core_1 = require("@aws-amplify/core");
const utils_1 = require("@aws-amplify/core/internals/utils");
const cacheTokens_1 = require("../tokenProvider/cacheTokens");
const tokenProvider_1 = require("../tokenProvider");
const models_1 = require("../types/models");
const signInWithRedirectStore_1 = require("../utils/signInWithRedirectStore");
const AuthError_1 = require("../../../errors/AuthError");
const Auth_1 = require("../../../types/Auth");
const AuthErrorStrings_1 = require("../../../common/AuthErrorStrings");
const Errors_1 = require("../../../Errors");
const utils_2 = require("../../../utils");
const signInHelpers_1 = require("../utils/signInHelpers");
const oauth_1 = require("../utils/oauth");
const getCurrentUser_1 = require("./getCurrentUser");
const getRedirectUrl_1 = require("../utils/oauth/getRedirectUrl");
/**
 * Signs in a user with OAuth. Redirects the application to an Identity Provider.
 *
 * @param input - The SignInWithRedirectInput object, if empty it will redirect to Cognito HostedUI
 *
 * @throws AuthTokenConfigException - Thrown when the userpool config is invalid.
 * @throws OAuthNotConfigureException - Thrown when the oauth config is invalid.
 */
async function signInWithRedirect(input) {
    const authConfig = core_1.Amplify.getConfig().Auth?.Cognito;
    (0, utils_1.assertTokenProviderConfig)(authConfig);
    (0, utils_1.assertOAuthConfig)(authConfig);
    exports.store.setAuthConfig(authConfig);
    await (0, signInHelpers_1.assertUserNotAuthenticated)();
    let provider = 'COGNITO'; // Default
    if (typeof input?.provider === 'string') {
        provider = models_1.cognitoHostedUIIdentityProviderMap[input.provider];
    }
    else if (input?.provider?.custom) {
        provider = input.provider.custom;
    }
    return oauthSignIn({
        oauthConfig: authConfig.loginWith.oauth,
        clientId: authConfig.userPoolClientId,
        provider,
        customState: input?.customState,
        preferPrivateSession: input?.options?.preferPrivateSession,
    });
}
exports.signInWithRedirect = signInWithRedirect;
exports.store = new signInWithRedirectStore_1.DefaultOAuthStore(core_1.defaultStorage);
async function oauthSignIn({ oauthConfig, provider, clientId, customState, preferPrivateSession, }) {
    const { domain, redirectSignIn, responseType, scopes } = oauthConfig;
    const randomState = (0, oauth_1.generateState)();
    /* encodeURIComponent is not URL safe, use urlSafeEncode instead. Cognito
    single-encodes/decodes url on first sign in and double-encodes/decodes url
    when user already signed in. Using encodeURIComponent, Base32, Base64 add
    characters % or = which on further encoding becomes unsafe. '=' create issue
    for parsing query params.
    Refer: https://github.com/aws-amplify/amplify-js/issues/5218 */
    const state = customState
        ? `${randomState}-${(0, utils_1.urlSafeEncode)(customState)}`
        : randomState;
    const { value, method, toCodeChallenge } = (0, oauth_1.generateCodeVerifier)(128);
    exports.store.storeOAuthInFlight(true);
    exports.store.storeOAuthState(state);
    exports.store.storePKCE(value);
    const queryString = Object.entries({
        redirect_uri: (0, getRedirectUrl_1.getRedirectUrl)(oauthConfig.redirectSignIn),
        response_type: responseType,
        client_id: clientId,
        identity_provider: provider,
        scope: scopes.join(' '),
        state,
        ...(responseType === 'code' && {
            code_challenge: toCodeChallenge(),
            code_challenge_method: method,
        }),
    })
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
    // TODO(v6): use URL object instead
    const oAuthUrl = `https://${domain}/oauth2/authorize?${queryString}`;
    const { type, error, url } = (await (0, utils_2.openAuthSession)(oAuthUrl, redirectSignIn, preferPrivateSession)) ??
        {};
    if (type === 'success' && url) {
        // ensure the code exchange completion resolves the signInWithRedirect
        // returned promise in react-native
        await handleAuthResponse({
            currentUrl: url,
            clientId,
            domain,
            redirectUri: redirectSignIn[0],
            responseType,
            userAgentValue: (0, utils_2.getAuthUserAgentValue)(utils_1.AuthAction.SignInWithRedirect),
            preferPrivateSession,
        });
    }
    if (type === 'error') {
        handleFailure(String(error));
    }
}
exports.oauthSignIn = oauthSignIn;
async function handleCodeFlow({ currentUrl, userAgentValue, clientId, redirectUri, domain, preferPrivateSession, }) {
    /* Convert URL into an object with parameters as keys
{ redirect_uri: 'http://localhost:3000/', response_type: 'code', ...} */
    const url = new utils_1.AmplifyUrl(currentUrl);
    let validatedState;
    try {
        validatedState = await validateStateFromURL(url);
    }
    catch (err) {
        invokeAndClearPromise();
        // clear temp values
        await exports.store.clearOAuthInflightData();
        return;
    }
    const code = url.searchParams.get('code');
    if (!code) {
        await exports.store.clearOAuthData();
        invokeAndClearPromise();
        return;
    }
    const oAuthTokenEndpoint = 'https://' + domain + '/oauth2/token';
    // TODO(v6): check hub events
    // dispatchAuthEvent(
    // 	'codeFlow',
    // 	{},
    // 	`Retrieving tokens from ${oAuthTokenEndpoint}`
    // );
    const codeVerifier = await exports.store.loadPKCE();
    const oAuthTokenBody = {
        grant_type: 'authorization_code',
        code,
        client_id: clientId,
        redirect_uri: redirectUri,
        ...(codeVerifier ? { code_verifier: codeVerifier } : {}),
    };
    const body = Object.entries(oAuthTokenBody)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
    const { access_token, refresh_token, id_token, error, token_type, expires_in, } = await (await fetch(oAuthTokenEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            [utils_1.USER_AGENT_HEADER]: userAgentValue,
        },
        body,
    })).json();
    if (error) {
        invokeAndClearPromise();
        handleFailure(error);
    }
    await exports.store.clearOAuthInflightData();
    const username = (access_token && (0, utils_1.decodeJWT)(access_token).payload.username) ?? 'username';
    await (0, cacheTokens_1.cacheCognitoTokens)({
        username,
        AccessToken: access_token,
        IdToken: id_token,
        RefreshToken: refresh_token,
        TokenType: token_type,
        ExpiresIn: expires_in,
    });
    return completeFlow({
        redirectUri,
        state: validatedState,
        preferPrivateSession,
    });
}
async function handleImplicitFlow({ currentUrl, redirectUri, preferPrivateSession, }) {
    // hash is `null` if `#` doesn't exist on URL
    const url = new utils_1.AmplifyUrl(currentUrl);
    const { id_token, access_token, state, token_type, expires_in } = (url.hash ?? '#')
        .substring(1) // Remove # from returned code
        .split('&')
        .map(pairings => pairings.split('='))
        .reduce((accum, [k, v]) => ({ ...accum, [k]: v }), {
        id_token: undefined,
        access_token: undefined,
        state: undefined,
        token_type: undefined,
        expires_in: undefined,
    });
    if (!access_token) {
        await exports.store.clearOAuthData();
        invokeAndClearPromise();
        return;
    }
    try {
        validateState(state);
    }
    catch (error) {
        invokeAndClearPromise();
        return;
    }
    const username = (access_token && (0, utils_1.decodeJWT)(access_token).payload.username) ?? 'username';
    await (0, cacheTokens_1.cacheCognitoTokens)({
        username,
        AccessToken: access_token,
        IdToken: id_token,
        TokenType: token_type,
        ExpiresIn: expires_in,
    });
    return completeFlow({ redirectUri, state, preferPrivateSession });
}
async function completeFlow({ redirectUri, state, preferPrivateSession, }) {
    await exports.store.clearOAuthData();
    await exports.store.storeOAuthSignIn(true, preferPrivateSession);
    if (isCustomState(state)) {
        core_1.Hub.dispatch('auth', {
            event: 'customOAuthState',
            data: (0, utils_1.urlSafeDecode)(getCustomState(state)),
        }, 'Auth', utils_1.AMPLIFY_SYMBOL);
    }
    core_1.Hub.dispatch('auth', { event: 'signInWithRedirect' }, 'Auth', utils_1.AMPLIFY_SYMBOL);
    core_1.Hub.dispatch('auth', { event: 'signedIn', data: await (0, getCurrentUser_1.getCurrentUser)() }, 'Auth', utils_1.AMPLIFY_SYMBOL);
    clearHistory(redirectUri);
    invokeAndClearPromise();
}
async function handleAuthResponse({ currentUrl, userAgentValue, clientId, redirectUri, responseType, domain, preferPrivateSession, }) {
    try {
        const urlParams = new utils_1.AmplifyUrl(currentUrl);
        const error = urlParams.searchParams.get('error');
        const errorMessage = urlParams.searchParams.get('error_description');
        if (error) {
            handleFailure(errorMessage);
        }
        if (responseType === 'code') {
            return await handleCodeFlow({
                currentUrl,
                userAgentValue,
                clientId,
                redirectUri,
                domain,
                preferPrivateSession,
            });
        }
        else {
            return await handleImplicitFlow({
                currentUrl,
                redirectUri,
                preferPrivateSession,
            });
        }
    }
    catch (e) {
        throw e;
    }
}
async function validateStateFromURL(urlParams) {
    if (!urlParams) {
    }
    const returnedState = urlParams.searchParams.get('state');
    validateState(returnedState);
    return returnedState;
}
function validateState(state) {
    let savedState;
    exports.store.loadOAuthState().then(resp => {
        savedState = resp;
    });
    // This is because savedState only exists if the flow was initiated by Amplify
    if (savedState && state && savedState !== state) {
        throw new AuthError_1.AuthError({
            name: Auth_1.AuthErrorTypes.OAuthSignInError,
            message: 'An error occurred while validating the state',
            recoverySuggestion: 'Try to initiate an OAuth flow from Amplify',
        });
    }
}
function handleFailure(errorMessage) {
    core_1.Hub.dispatch('auth', { event: 'signInWithRedirect_failure' }, 'Auth', utils_1.AMPLIFY_SYMBOL);
    throw new AuthError_1.AuthError({
        message: errorMessage ?? '',
        name: AuthErrorStrings_1.AuthErrorCodes.OAuthSignInError,
        recoverySuggestion: Errors_1.authErrorMessages.oauthSignInError.log,
    });
}
async function parseRedirectURL() {
    const authConfig = core_1.Amplify.getConfig().Auth?.Cognito;
    try {
        (0, utils_1.assertTokenProviderConfig)(authConfig);
        exports.store.setAuthConfig(authConfig);
    }
    catch (_err) {
        // Token provider not configure nothing to do
        return;
    }
    // No OAuth inflight doesnt need to parse the url
    if (!(await exports.store.loadOAuthInFlight())) {
        return;
    }
    try {
        (0, utils_1.assertOAuthConfig)(authConfig);
    }
    catch (err) {
        // TODO(v6): this should warn you have signInWithRedirect but is not configured
        return;
    }
    try {
        const currentUrl = window.location.href;
        const { loginWith, userPoolClientId } = authConfig;
        const { domain, redirectSignIn, responseType } = loginWith.oauth;
        handleAuthResponse({
            currentUrl,
            clientId: userPoolClientId,
            domain,
            redirectUri: redirectSignIn[0],
            responseType,
            userAgentValue: (0, utils_2.getAuthUserAgentValue)(utils_1.AuthAction.SignInWithRedirect),
        });
    }
    catch (err) {
        // is ok if there is not OAuthConfig
    }
}
function urlListener() {
    // Listen configure to parse url
    parseRedirectURL();
    core_1.Hub.listen('core', capsule => {
        if (capsule.payload.event === 'configure') {
            parseRedirectURL();
        }
    });
}
(0, utils_1.isBrowser)() && urlListener();
// This has a reference for listeners that requires to be notified, TokenOrchestrator use this for load tokens
let inflightPromiseResolvers = [];
const invokeAndClearPromise = () => {
    for (const promiseResolver of inflightPromiseResolvers) {
        promiseResolver();
    }
    inflightPromiseResolvers = [];
};
(0, utils_1.isBrowser)() &&
    tokenProvider_1.cognitoUserPoolsTokenProvider.setWaitForInflightOAuth(() => new Promise(async (res, _rej) => {
        if (!(await exports.store.loadOAuthInFlight())) {
            res();
        }
        else {
            inflightPromiseResolvers.push(res);
        }
        return;
    }));
function clearHistory(redirectUri) {
    if (typeof window !== 'undefined' && typeof window.history !== 'undefined') {
        window.history.replaceState({}, '', redirectUri);
    }
}
function isCustomState(state) {
    return /-/.test(state);
}
function getCustomState(state) {
    return state.split('-').splice(1).join('-');
}
