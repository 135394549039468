"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.unauthenticatedHandler = void 0;
const retry_1 = require("../middleware/retry");
const userAgent_1 = require("../middleware/userAgent");
const composeTransferHandler_1 = require("../internal/composeTransferHandler");
const fetch_1 = require("./fetch");
exports.unauthenticatedHandler = (0, composeTransferHandler_1.composeTransferHandler)(fetch_1.fetchTransferHandler, [userAgent_1.userAgentMiddleware, retry_1.retryMiddleware]);
