"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.nuxtSSRDetect = exports.nuxtWebDetect = void 0;
const helpers_1 = require("./helpers");
// Tested with nuxt 2.15 / vue 2.7
function nuxtWebDetect() {
    return ((0, helpers_1.windowExists)() &&
        // @ts-ignore
        (window['__NUXT__'] !== undefined || window['$nuxt'] !== undefined));
}
exports.nuxtWebDetect = nuxtWebDetect;
function nuxtSSRDetect() {
    // @ts-ignore
    return (0, helpers_1.globalExists)() && typeof global['__NUXT_PATHS__'] !== 'undefined';
}
exports.nuxtSSRDetect = nuxtSSRDetect;
