"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleOAuthSignOut = void 0;
const completeOAuthSignOut_1 = require("./completeOAuthSignOut");
const oAuthSignOutRedirect_1 = require("./oAuthSignOutRedirect");
const handleOAuthSignOut = async (cognitoConfig, store) => {
    const { isOAuthSignIn } = await store.loadOAuthSignIn();
    // Clear everything before attempting to visted logout endpoint since the current application
    // state could be wiped away on redirect
    await (0, completeOAuthSignOut_1.completeOAuthSignOut)(store);
    if (isOAuthSignIn) {
        // On web, this will always end up being a void action
        return (0, oAuthSignOutRedirect_1.oAuthSignOutRedirect)(cognitoConfig);
    }
};
exports.handleOAuthSignOut = handleOAuthSignOut;
