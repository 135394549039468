"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Amplify = exports.AmplifyClass = void 0;
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const Auth_1 = require("./Auth");
const Hub_1 = require("../Hub");
const parseAWSExports_1 = require("../parseAWSExports");
const utils_1 = require("../utils");
class AmplifyClass {
    constructor() {
        this.resourcesConfig = {};
        this.libraryOptions = {};
        this.Auth = new Auth_1.AuthClass();
    }
    /**
     * Configures Amplify for use with your back-end resources.
     *
     * @remarks
     * This API does not perform any merging of either `resourcesConfig` or `libraryOptions`. The most recently
     * provided values will be used after configuration.
     *
     * @remarks
     * `configure` can be used to specify additional library options where available for supported categories.
     *
     * @param resourceConfig - Back-end resource configuration. Typically provided via the `aws-exports.js` file.
     * @param libraryOptions - Additional options for customizing the behavior of the library.
     */
    configure(resourcesConfig, libraryOptions) {
        let resolvedResourceConfig;
        if (Object.keys(resourcesConfig).some(key => key.startsWith('aws_'))) {
            resolvedResourceConfig = (0, parseAWSExports_1.parseAWSExports)(resourcesConfig);
        }
        else {
            resolvedResourceConfig = resourcesConfig;
        }
        this.resourcesConfig = resolvedResourceConfig;
        if (libraryOptions) {
            this.libraryOptions = libraryOptions;
        }
        // Make resource config immutable
        this.resourcesConfig = (0, utils_1.deepFreeze)(this.resourcesConfig);
        this.Auth.configure(this.resourcesConfig.Auth, this.libraryOptions.Auth);
        Hub_1.Hub.dispatch('core', {
            event: 'configure',
            data: resourcesConfig,
        }, 'Configure', Hub_1.AMPLIFY_SYMBOL);
    }
    /**
     * Provides access to the current back-end resource configuration for the Library.
     *
     * @returns Returns the immutable back-end resource configuration.
     */
    getConfig() {
        return this.resourcesConfig;
    }
}
exports.AmplifyClass = AmplifyClass;
/**
 * The `Amplify` utility is used to configure the library.
 *
 * @remarks
 * `Amplify` is responsible for orchestrating cross-category communication within the library.
 */
exports.Amplify = new AmplifyClass();
