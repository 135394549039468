"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.refreshAuthTokens = exports.TokenOrchestrator = exports.DefaultTokenStore = exports.tokenOrchestrator = exports.cognitoUserPoolsTokenProvider = void 0;
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const core_1 = require("@aws-amplify/core");
const refreshAuthTokens_1 = require("../utils/refreshAuthTokens");
Object.defineProperty(exports, "refreshAuthTokens", { enumerable: true, get: function () { return refreshAuthTokens_1.refreshAuthTokens; } });
const TokenStore_1 = require("./TokenStore");
Object.defineProperty(exports, "DefaultTokenStore", { enumerable: true, get: function () { return TokenStore_1.DefaultTokenStore; } });
const TokenOrchestrator_1 = require("./TokenOrchestrator");
Object.defineProperty(exports, "TokenOrchestrator", { enumerable: true, get: function () { return TokenOrchestrator_1.TokenOrchestrator; } });
class CognitoUserPoolsTokenProviderClass {
    constructor() {
        this.authTokenStore = new TokenStore_1.DefaultTokenStore();
        this.authTokenStore.setKeyValueStorage(core_1.defaultStorage);
        this.tokenOrchestrator = new TokenOrchestrator_1.TokenOrchestrator();
        this.tokenOrchestrator.setAuthTokenStore(this.authTokenStore);
        this.tokenOrchestrator.setTokenRefresher(refreshAuthTokens_1.refreshAuthTokens);
    }
    getTokens({ forceRefresh } = { forceRefresh: false }) {
        return this.tokenOrchestrator.getTokens({ forceRefresh });
    }
    setKeyValueStorage(keyValueStorage) {
        this.authTokenStore.setKeyValueStorage(keyValueStorage);
    }
    setWaitForInflightOAuth(waitForInflightOAuth) {
        this.tokenOrchestrator.setWaitForInflightOAuth(waitForInflightOAuth);
    }
    setAuthConfig(authConfig) {
        this.authTokenStore.setAuthConfig(authConfig);
        this.tokenOrchestrator.setAuthConfig(authConfig);
    }
}
exports.cognitoUserPoolsTokenProvider = new CognitoUserPoolsTokenProviderClass();
exports.tokenOrchestrator = exports.cognitoUserPoolsTokenProvider.tokenOrchestrator;
