"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.signUp = void 0;
const core_1 = require("@aws-amplify/core");
const utils_1 = require("@aws-amplify/core/internals/utils");
const CognitoIdentityProvider_1 = require("../utils/clients/CognitoIdentityProvider");
const assertValidationError_1 = require("../../../errors/utils/assertValidationError");
const validation_1 = require("../../../errors/types/validation");
const utils_2 = require("../utils/clients/CognitoIdentityProvider/utils");
const apiHelpers_1 = require("../utils/apiHelpers");
const signUpHelpers_1 = require("../utils/signUpHelpers");
const autoSignIn_1 = require("./autoSignIn");
const utils_3 = require("../../../utils");
/**
 * Creates a user
 *
 * @param input - The SignUpInput object
 * @returns SignUpOutput
 * @throws service: {@link SignUpException } - Cognito service errors thrown during the sign-up process.
 * @throws validation: {@link AuthValidationErrorCode } - Validation errors thrown either username or password
 *  are not defined.
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
async function signUp(input) {
    const { username, password, options } = input;
    const authConfig = core_1.Amplify.getConfig().Auth?.Cognito;
    const signUpVerificationMethod = authConfig?.signUpVerificationMethod ?? 'code';
    const { clientMetadata, validationData, autoSignIn } = input.options ?? {};
    (0, utils_1.assertTokenProviderConfig)(authConfig);
    (0, assertValidationError_1.assertValidationError)(!!username, validation_1.AuthValidationErrorCode.EmptySignUpUsername);
    (0, assertValidationError_1.assertValidationError)(!!password, validation_1.AuthValidationErrorCode.EmptySignUpPassword);
    const signInServiceOptions = typeof autoSignIn !== 'boolean' ? autoSignIn : undefined;
    const signInInput = {
        username,
        options: signInServiceOptions,
    };
    // if the authFlowType is 'CUSTOM_WITHOUT_SRP' then we don't include the password
    if (signInServiceOptions?.authFlowType !== 'CUSTOM_WITHOUT_SRP') {
        signInInput['password'] = password;
    }
    if (signInServiceOptions || autoSignIn === true) {
        (0, signUpHelpers_1.setUsernameUsedForAutoSignIn)(username);
        (0, signUpHelpers_1.setAutoSignInStarted)(true);
    }
    const clientOutput = await (0, CognitoIdentityProvider_1.signUp)({
        region: (0, utils_2.getRegion)(authConfig.userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.SignUp),
    }, {
        Username: username,
        Password: password,
        UserAttributes: options?.userAttributes && (0, apiHelpers_1.toAttributeType)(options?.userAttributes),
        ClientMetadata: clientMetadata,
        ValidationData: validationData && (0, apiHelpers_1.toAttributeType)(validationData),
        ClientId: authConfig.userPoolClientId,
    });
    const { UserSub, CodeDeliveryDetails } = clientOutput;
    if ((0, signUpHelpers_1.isSignUpComplete)(clientOutput) && (0, signUpHelpers_1.isAutoSignInStarted)()) {
        (0, autoSignIn_1.setAutoSignIn)((0, signUpHelpers_1.autoSignInUserConfirmed)(signInInput));
        return {
            isSignUpComplete: true,
            nextStep: {
                signUpStep: 'COMPLETE_AUTO_SIGN_IN',
            },
        };
    }
    else if ((0, signUpHelpers_1.isSignUpComplete)(clientOutput) && !(0, signUpHelpers_1.isAutoSignInStarted)()) {
        return {
            isSignUpComplete: true,
            nextStep: {
                signUpStep: 'DONE',
            },
        };
    }
    else if (!(0, signUpHelpers_1.isSignUpComplete)(clientOutput) &&
        (0, signUpHelpers_1.isAutoSignInStarted)() &&
        signUpVerificationMethod === 'code') {
        (0, signUpHelpers_1.handleCodeAutoSignIn)(signInInput);
    }
    else if (!(0, signUpHelpers_1.isSignUpComplete)(clientOutput) &&
        (0, signUpHelpers_1.isAutoSignInStarted)() &&
        signUpVerificationMethod === 'link') {
        (0, autoSignIn_1.setAutoSignIn)((0, signUpHelpers_1.autoSignInWhenUserIsConfirmedWithLink)(signInInput));
        return {
            isSignUpComplete: false,
            nextStep: {
                signUpStep: 'COMPLETE_AUTO_SIGN_IN',
                codeDeliveryDetails: {
                    deliveryMedium: CodeDeliveryDetails?.DeliveryMedium,
                    destination: CodeDeliveryDetails?.Destination,
                    attributeName: CodeDeliveryDetails?.AttributeName,
                },
            },
            userId: UserSub,
        };
    }
    return {
        isSignUpComplete: false,
        nextStep: {
            signUpStep: 'CONFIRM_SIGN_UP',
            codeDeliveryDetails: {
                deliveryMedium: CodeDeliveryDetails?.DeliveryMedium,
                destination: CodeDeliveryDetails?.Destination,
                attributeName: CodeDeliveryDetails?.AttributeName,
            },
        },
        userId: UserSub,
    };
}
exports.signUp = signUp;
