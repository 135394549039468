"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.textEncoder = void 0;
exports.textEncoder = {
    convert(input) {
        return new TextEncoder().encode(input);
    },
};
