"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenOrchestrator = void 0;
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const core_1 = require("@aws-amplify/core");
const utils_1 = require("@aws-amplify/core/internals/utils");
const assertServiceError_1 = require("../../../errors/utils/assertServiceError");
const AuthError_1 = require("../../../errors/AuthError");
class TokenOrchestrator {
    constructor() {
        this.waitForInflightOAuth = async () => { };
    }
    setAuthConfig(authConfig) {
        this.authConfig = authConfig;
    }
    setTokenRefresher(tokenRefresher) {
        this.tokenRefresher = tokenRefresher;
    }
    setAuthTokenStore(tokenStore) {
        this.tokenStore = tokenStore;
    }
    setWaitForInflightOAuth(waitForInflightOAuth) {
        this.waitForInflightOAuth = waitForInflightOAuth;
    }
    getTokenStore() {
        if (!this.tokenStore) {
            throw new AuthError_1.AuthError({
                name: 'EmptyTokenStoreException',
                message: 'TokenStore not set',
            });
        }
        return this.tokenStore;
    }
    getTokenRefresher() {
        if (!this.tokenRefresher) {
            throw new AuthError_1.AuthError({
                name: 'EmptyTokenRefresherException',
                message: 'TokenRefresher not set',
            });
        }
        return this.tokenRefresher;
    }
    async getTokens(options) {
        let tokens;
        try {
            (0, utils_1.assertTokenProviderConfig)(this.authConfig?.Cognito);
        }
        catch (_err) {
            // Token provider not configured
            return null;
        }
        await this.waitForInflightOAuth();
        tokens = await this.getTokenStore().loadTokens();
        const username = await this.getTokenStore().getLastAuthUser();
        if (tokens === null) {
            return null;
        }
        const idTokenExpired = !!tokens?.idToken &&
            (0, utils_1.isTokenExpired)({
                expiresAt: (tokens.idToken?.payload?.exp ?? 0) * 1000,
                clockDrift: tokens.clockDrift ?? 0,
            });
        const accessTokenExpired = (0, utils_1.isTokenExpired)({
            expiresAt: (tokens.accessToken?.payload?.exp ?? 0) * 1000,
            clockDrift: tokens.clockDrift ?? 0,
        });
        if (options?.forceRefresh || idTokenExpired || accessTokenExpired) {
            tokens = await this.refreshTokens({
                tokens,
                username,
            });
            if (tokens === null) {
                return null;
            }
        }
        return {
            accessToken: tokens?.accessToken,
            idToken: tokens?.idToken,
            signInDetails: tokens?.signInDetails,
        };
    }
    async refreshTokens({ tokens, username, }) {
        try {
            const newTokens = await this.getTokenRefresher()({
                tokens,
                authConfig: this.authConfig,
                username,
            });
            await this.setTokens({ tokens: newTokens });
            core_1.Hub.dispatch('auth', { event: 'tokenRefresh' }, 'Auth', utils_1.AMPLIFY_SYMBOL);
            return newTokens;
        }
        catch (err) {
            return this.handleErrors(err);
        }
    }
    handleErrors(err) {
        (0, assertServiceError_1.assertServiceError)(err);
        if (err.message !== 'Network error') {
            // TODO(v6): Check errors on client
            this.clearTokens();
        }
        if (err.name.startsWith('NotAuthorizedException')) {
            return null;
        }
        else {
            core_1.Hub.dispatch('auth', { event: 'tokenRefresh_failure' }, 'Auth', utils_1.AMPLIFY_SYMBOL);
            throw err;
        }
    }
    async setTokens({ tokens }) {
        return this.getTokenStore().storeTokens(tokens);
    }
    async clearTokens() {
        return this.getTokenStore().clearTokens();
    }
    getDeviceMetadata(username) {
        return this.getTokenStore().getDeviceMetadata(username);
    }
    clearDeviceMetadata(username) {
        return this.getTokenStore().clearDeviceMetadata(username);
    }
}
exports.TokenOrchestrator = TokenOrchestrator;
