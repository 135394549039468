"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteUserAttributes = exports.listDevices = exports.updateDeviceStatus = exports.verifyUserAttribute = exports.updateUserAttributes = exports.globalSignOut = exports.getUserAttributeVerificationCode = exports.deleteUser = exports.forgetDevice = exports.confirmDevice = exports.changePassword = exports.getUser = exports.setUserMFAPreference = exports.associateSoftwareToken = exports.verifySoftwareToken = exports.resendConfirmationCode = exports.respondToAuthChallenge = exports.confirmForgotPassword = exports.forgotPassword = exports.confirmSignUp = exports.signUp = exports.revokeToken = exports.initiateAuth = void 0;
const composers_1 = require("@aws-amplify/core/internals/aws-client-utils/composers");
const base_1 = require("./base");
const aws_client_utils_1 = require("@aws-amplify/core/internals/aws-client-utils");
const assertServiceError_1 = require("../../../../../errors/utils/assertServiceError");
const AuthError_1 = require("../../../../../errors/AuthError");
const buildUserPoolSerializer = (operation) => (input, endpoint) => {
    const headers = (0, base_1.getSharedHeaders)(operation);
    const body = JSON.stringify(input);
    return (0, base_1.buildHttpRpcRequest)(endpoint, headers, body);
};
const buildUserPoolDeserializer = () => {
    return async (response) => {
        if (response.statusCode >= 300) {
            const error = await (0, aws_client_utils_1.parseJsonError)(response);
            (0, assertServiceError_1.assertServiceError)(error);
            throw new AuthError_1.AuthError({ name: error.name, message: error.message });
        }
        else {
            const body = await (0, aws_client_utils_1.parseJsonBody)(response);
            return body;
        }
    };
};
const handleEmptyResponseDeserializer = () => {
    return async (response) => {
        if (response.statusCode >= 300) {
            const error = await (0, aws_client_utils_1.parseJsonError)(response);
            (0, assertServiceError_1.assertServiceError)(error);
            throw new AuthError_1.AuthError({ name: error.name, message: error.message });
        }
        else {
            return undefined;
        }
    };
};
exports.initiateAuth = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('InitiateAuth'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.revokeToken = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('RevokeToken'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.signUp = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('SignUp'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.confirmSignUp = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('ConfirmSignUp'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.forgotPassword = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('ForgotPassword'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.confirmForgotPassword = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('ConfirmForgotPassword'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.respondToAuthChallenge = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('RespondToAuthChallenge'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.resendConfirmationCode = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('ResendConfirmationCode'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.verifySoftwareToken = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('VerifySoftwareToken'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.associateSoftwareToken = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('AssociateSoftwareToken'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.setUserMFAPreference = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('SetUserMFAPreference'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.getUser = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('GetUser'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.changePassword = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('ChangePassword'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.confirmDevice = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('ConfirmDevice'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.forgetDevice = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('ForgetDevice'), handleEmptyResponseDeserializer(), base_1.defaultConfig);
exports.deleteUser = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('DeleteUser'), handleEmptyResponseDeserializer(), base_1.defaultConfig);
exports.getUserAttributeVerificationCode = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('GetUserAttributeVerificationCode'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.globalSignOut = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('GlobalSignOut'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.updateUserAttributes = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('UpdateUserAttributes'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.verifyUserAttribute = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('VerifyUserAttribute'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.updateDeviceStatus = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('UpdateDeviceStatus'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.listDevices = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('ListDevices'), buildUserPoolDeserializer(), base_1.defaultConfig);
exports.deleteUserAttributes = (0, composers_1.composeServiceApi)(base_1.cognitoUserPoolTransferHandler, buildUserPoolSerializer('DeleteUserAttributes'), buildUserPoolDeserializer(), base_1.defaultConfig);
