"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActiveSignInUsername = exports.setActiveSignInUsername = exports.retryOnResourceNotFoundException = exports.getNewDeviceMetatada = exports.assertUserNotAuthenticated = exports.isMFATypeEnabled = exports.parseMFATypes = exports.getMFATypes = exports.getMFAType = exports.mapMfaType = exports.handleChallengeName = exports.createAttributes = exports.parseAttributes = exports.getSignInResultFromError = exports.getTOTPSetupDetails = exports.getSignInResult = exports.handlePasswordVerifierChallenge = exports.handleCustomSRPAuthFlow = exports.handleCustomAuthFlowWithoutSRP = exports.handleUserSRPAuthFlow = exports.handleUserPasswordAuthFlow = exports.handleCompleteNewPasswordChallenge = exports.handleSoftwareTokenMFAChallenge = exports.handleSMSMFAChallenge = exports.handleSelectMFATypeChallenge = exports.handleMFASetupChallenge = exports.handleCustomChallenge = void 0;
const core_1 = require("@aws-amplify/core");
const utils_1 = require("@aws-amplify/core/internals/utils");
const BigInteger_1 = require("./srp/BigInteger");
const srp_1 = require("./srp");
const AuthError_1 = require("../../../errors/AuthError");
const errors_1 = require("../types/errors");
const AuthErrorStrings_1 = require("../../../common/AuthErrorStrings");
const validation_1 = require("../../../errors/types/validation");
const assertValidationError_1 = require("../../../errors/utils/assertValidationError");
const signInStore_1 = require("./signInStore");
const CognitoIdentityProvider_1 = require("./clients/CognitoIdentityProvider");
const utils_2 = require("./clients/CognitoIdentityProvider/utils");
const constants_1 = require("../../../errors/constants");
const getCurrentUser_1 = require("../apis/getCurrentUser");
const types_1 = require("./types");
const utils_3 = require("../../../utils");
const userContextData_1 = require("./userContextData");
const USER_ATTRIBUTES = 'userAttributes.';
async function handleCustomChallenge({ challengeResponse, clientMetadata, session, username, config, tokenOrchestrator, }) {
    const { userPoolId, userPoolClientId } = config;
    const challengeResponses = {
        USERNAME: username,
        ANSWER: challengeResponse,
    };
    const deviceMetadata = await tokenOrchestrator?.getDeviceMetadata(username);
    if (deviceMetadata && deviceMetadata.deviceKey) {
        challengeResponses['DEVICE_KEY'] = deviceMetadata.deviceKey;
    }
    const UserContextData = (0, userContextData_1.getUserContextData)({
        username,
        userPoolId,
        userPoolClientId,
    });
    const jsonReq = {
        ChallengeName: 'CUSTOM_CHALLENGE',
        ChallengeResponses: challengeResponses,
        Session: session,
        ClientMetadata: clientMetadata,
        ClientId: userPoolClientId,
        UserContextData,
    };
    const response = await (0, CognitoIdentityProvider_1.respondToAuthChallenge)({
        region: (0, utils_2.getRegion)(userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.ConfirmSignIn),
    }, jsonReq);
    if (response.ChallengeName === 'DEVICE_SRP_AUTH') {
        return handleDeviceSRPAuth({
            username,
            config,
            clientMetadata,
            session: response.Session,
            tokenOrchestrator,
        });
    }
    return response;
}
exports.handleCustomChallenge = handleCustomChallenge;
async function handleMFASetupChallenge({ challengeResponse, username, clientMetadata, session, deviceName, config, }) {
    const { userPoolId, userPoolClientId } = config;
    const challengeResponses = {
        USERNAME: username,
    };
    const { Session } = await (0, CognitoIdentityProvider_1.verifySoftwareToken)({
        region: (0, utils_2.getRegion)(userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.ConfirmSignIn),
    }, {
        UserCode: challengeResponse,
        Session: session,
        FriendlyDeviceName: deviceName,
    });
    signInStore_1.signInStore.dispatch({
        type: 'SET_SIGN_IN_SESSION',
        value: Session,
    });
    const jsonReq = {
        ChallengeName: 'MFA_SETUP',
        ChallengeResponses: challengeResponses,
        Session,
        ClientMetadata: clientMetadata,
        ClientId: userPoolClientId,
    };
    return (0, CognitoIdentityProvider_1.respondToAuthChallenge)({ region: (0, utils_2.getRegion)(userPoolId) }, jsonReq);
}
exports.handleMFASetupChallenge = handleMFASetupChallenge;
async function handleSelectMFATypeChallenge({ challengeResponse, username, clientMetadata, session, config, }) {
    const { userPoolId, userPoolClientId } = config;
    (0, assertValidationError_1.assertValidationError)(challengeResponse === 'TOTP' || challengeResponse === 'SMS', validation_1.AuthValidationErrorCode.IncorrectMFAMethod);
    const challengeResponses = {
        USERNAME: username,
        ANSWER: mapMfaType(challengeResponse),
    };
    const UserContextData = (0, userContextData_1.getUserContextData)({
        username,
        userPoolId,
        userPoolClientId,
    });
    const jsonReq = {
        ChallengeName: 'SELECT_MFA_TYPE',
        ChallengeResponses: challengeResponses,
        Session: session,
        ClientMetadata: clientMetadata,
        ClientId: userPoolClientId,
        UserContextData,
    };
    return (0, CognitoIdentityProvider_1.respondToAuthChallenge)({
        region: (0, utils_2.getRegion)(userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.ConfirmSignIn),
    }, jsonReq);
}
exports.handleSelectMFATypeChallenge = handleSelectMFATypeChallenge;
async function handleSMSMFAChallenge({ challengeResponse, clientMetadata, session, username, config, }) {
    const { userPoolId, userPoolClientId } = config;
    const challengeResponses = {
        USERNAME: username,
        SMS_MFA_CODE: challengeResponse,
    };
    const UserContextData = (0, userContextData_1.getUserContextData)({
        username,
        userPoolId,
        userPoolClientId,
    });
    const jsonReq = {
        ChallengeName: 'SMS_MFA',
        ChallengeResponses: challengeResponses,
        Session: session,
        ClientMetadata: clientMetadata,
        ClientId: userPoolClientId,
        UserContextData,
    };
    return (0, CognitoIdentityProvider_1.respondToAuthChallenge)({
        region: (0, utils_2.getRegion)(userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.ConfirmSignIn),
    }, jsonReq);
}
exports.handleSMSMFAChallenge = handleSMSMFAChallenge;
async function handleSoftwareTokenMFAChallenge({ challengeResponse, clientMetadata, session, username, config, }) {
    const { userPoolId, userPoolClientId } = config;
    const challengeResponses = {
        USERNAME: username,
        SOFTWARE_TOKEN_MFA_CODE: challengeResponse,
    };
    const UserContextData = (0, userContextData_1.getUserContextData)({
        username,
        userPoolId,
        userPoolClientId,
    });
    const jsonReq = {
        ChallengeName: 'SOFTWARE_TOKEN_MFA',
        ChallengeResponses: challengeResponses,
        Session: session,
        ClientMetadata: clientMetadata,
        ClientId: userPoolClientId,
        UserContextData,
    };
    return (0, CognitoIdentityProvider_1.respondToAuthChallenge)({
        region: (0, utils_2.getRegion)(userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.ConfirmSignIn),
    }, jsonReq);
}
exports.handleSoftwareTokenMFAChallenge = handleSoftwareTokenMFAChallenge;
async function handleCompleteNewPasswordChallenge({ challengeResponse, clientMetadata, session, username, requiredAttributes, config, }) {
    const { userPoolId, userPoolClientId } = config;
    const challengeResponses = {
        ...createAttributes(requiredAttributes),
        NEW_PASSWORD: challengeResponse,
        USERNAME: username,
    };
    const UserContextData = (0, userContextData_1.getUserContextData)({
        username,
        userPoolId,
        userPoolClientId,
    });
    const jsonReq = {
        ChallengeName: 'NEW_PASSWORD_REQUIRED',
        ChallengeResponses: challengeResponses,
        ClientMetadata: clientMetadata,
        Session: session,
        ClientId: userPoolClientId,
        UserContextData,
    };
    return (0, CognitoIdentityProvider_1.respondToAuthChallenge)({
        region: (0, utils_2.getRegion)(userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.ConfirmSignIn),
    }, jsonReq);
}
exports.handleCompleteNewPasswordChallenge = handleCompleteNewPasswordChallenge;
async function handleUserPasswordAuthFlow(username, password, clientMetadata, config, tokenOrchestrator) {
    const { userPoolClientId, userPoolId } = config;
    const authParameters = {
        USERNAME: username,
        PASSWORD: password,
    };
    const deviceMetadata = await tokenOrchestrator.getDeviceMetadata(username);
    if (deviceMetadata && deviceMetadata.deviceKey) {
        authParameters['DEVICE_KEY'] = deviceMetadata.deviceKey;
    }
    const UserContextData = (0, userContextData_1.getUserContextData)({
        username,
        userPoolId,
        userPoolClientId,
    });
    const jsonReq = {
        AuthFlow: 'USER_PASSWORD_AUTH',
        AuthParameters: authParameters,
        ClientMetadata: clientMetadata,
        ClientId: userPoolClientId,
        UserContextData,
    };
    const response = await (0, CognitoIdentityProvider_1.initiateAuth)({
        region: (0, utils_2.getRegion)(userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.SignIn),
    }, jsonReq);
    const activeUsername = response.ChallengeParameters?.USERNAME ??
        response.ChallengeParameters?.USER_ID_FOR_SRP ??
        username;
    setActiveSignInUsername(activeUsername);
    if (response.ChallengeName === 'DEVICE_SRP_AUTH')
        return handleDeviceSRPAuth({
            username: activeUsername,
            config,
            clientMetadata,
            session: response.Session,
            tokenOrchestrator,
        });
    return response;
}
exports.handleUserPasswordAuthFlow = handleUserPasswordAuthFlow;
async function handleUserSRPAuthFlow(username, password, clientMetadata, config, tokenOrchestrator) {
    const { userPoolId, userPoolClientId } = config;
    const userPoolName = userPoolId?.split('_')[1] || '';
    const authenticationHelper = await (0, srp_1.getAuthenticationHelper)(userPoolName);
    const authParameters = {
        USERNAME: username,
        SRP_A: authenticationHelper.A.toString(16),
    };
    const UserContextData = (0, userContextData_1.getUserContextData)({
        username,
        userPoolId,
        userPoolClientId,
    });
    const jsonReq = {
        AuthFlow: 'USER_SRP_AUTH',
        AuthParameters: authParameters,
        ClientMetadata: clientMetadata,
        ClientId: userPoolClientId,
        UserContextData,
    };
    const resp = await (0, CognitoIdentityProvider_1.initiateAuth)({
        region: (0, utils_2.getRegion)(userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.SignIn),
    }, jsonReq);
    const { ChallengeParameters: challengeParameters, Session: session } = resp;
    const activeUsername = challengeParameters?.USERNAME ?? username;
    setActiveSignInUsername(activeUsername);
    return retryOnResourceNotFoundException(handlePasswordVerifierChallenge, [
        password,
        challengeParameters,
        clientMetadata,
        session,
        authenticationHelper,
        config,
        tokenOrchestrator,
    ], activeUsername, tokenOrchestrator);
}
exports.handleUserSRPAuthFlow = handleUserSRPAuthFlow;
async function handleCustomAuthFlowWithoutSRP(username, clientMetadata, config, tokenOrchestrator) {
    const { userPoolClientId, userPoolId } = config;
    const { dispatch } = signInStore_1.signInStore;
    const authParameters = {
        USERNAME: username,
    };
    const deviceMetadata = await tokenOrchestrator.getDeviceMetadata(username);
    if (deviceMetadata && deviceMetadata.deviceKey) {
        authParameters['DEVICE_KEY'] = deviceMetadata.deviceKey;
    }
    const UserContextData = (0, userContextData_1.getUserContextData)({
        username,
        userPoolId,
        userPoolClientId,
    });
    const jsonReq = {
        AuthFlow: 'CUSTOM_AUTH',
        AuthParameters: authParameters,
        ClientMetadata: clientMetadata,
        ClientId: userPoolClientId,
        UserContextData,
    };
    const response = await (0, CognitoIdentityProvider_1.initiateAuth)({
        region: (0, utils_2.getRegion)(userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.SignIn),
    }, jsonReq);
    const activeUsername = response.ChallengeParameters?.USERNAME ?? username;
    setActiveSignInUsername(activeUsername);
    if (response.ChallengeName === 'DEVICE_SRP_AUTH')
        return handleDeviceSRPAuth({
            username: activeUsername,
            config,
            clientMetadata,
            session: response.Session,
            tokenOrchestrator,
        });
    return response;
}
exports.handleCustomAuthFlowWithoutSRP = handleCustomAuthFlowWithoutSRP;
async function handleCustomSRPAuthFlow(username, password, clientMetadata, config, tokenOrchestrator) {
    (0, utils_1.assertTokenProviderConfig)(config);
    const { userPoolId, userPoolClientId } = config;
    const userPoolName = userPoolId?.split('_')[1] || '';
    const authenticationHelper = await (0, srp_1.getAuthenticationHelper)(userPoolName);
    const authParameters = {
        USERNAME: username,
        SRP_A: authenticationHelper.A.toString(16),
        CHALLENGE_NAME: 'SRP_A',
    };
    const UserContextData = (0, userContextData_1.getUserContextData)({
        username,
        userPoolId,
        userPoolClientId,
    });
    const jsonReq = {
        AuthFlow: 'CUSTOM_AUTH',
        AuthParameters: authParameters,
        ClientMetadata: clientMetadata,
        ClientId: userPoolClientId,
        UserContextData,
    };
    const { ChallengeParameters: challengeParameters, Session: session } = await (0, CognitoIdentityProvider_1.initiateAuth)({
        region: (0, utils_2.getRegion)(userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.SignIn),
    }, jsonReq);
    const activeUsername = challengeParameters?.USERNAME ?? username;
    setActiveSignInUsername(activeUsername);
    return retryOnResourceNotFoundException(handlePasswordVerifierChallenge, [
        password,
        challengeParameters,
        clientMetadata,
        session,
        authenticationHelper,
        config,
        tokenOrchestrator,
    ], activeUsername, tokenOrchestrator);
}
exports.handleCustomSRPAuthFlow = handleCustomSRPAuthFlow;
async function handleDeviceSRPAuth({ username, config, clientMetadata, session, tokenOrchestrator, }) {
    const userPoolId = config.userPoolId;
    const clientId = config.userPoolClientId;
    const deviceMetadata = await tokenOrchestrator?.getDeviceMetadata(username);
    (0, types_1.assertDeviceMetadata)(deviceMetadata);
    const authenticationHelper = await (0, srp_1.getAuthenticationHelper)(deviceMetadata.deviceGroupKey);
    const challengeResponses = {
        USERNAME: username,
        SRP_A: authenticationHelper.A.toString(16),
        DEVICE_KEY: deviceMetadata.deviceKey,
    };
    const jsonReqResponseChallenge = {
        ChallengeName: 'DEVICE_SRP_AUTH',
        ClientId: clientId,
        ChallengeResponses: challengeResponses,
        ClientMetadata: clientMetadata,
        Session: session,
    };
    const { ChallengeParameters, Session } = await (0, CognitoIdentityProvider_1.respondToAuthChallenge)({ region: (0, utils_2.getRegion)(userPoolId) }, jsonReqResponseChallenge);
    return handleDevicePasswordVerifier(username, ChallengeParameters, clientMetadata, Session, authenticationHelper, config, tokenOrchestrator);
}
async function handleDevicePasswordVerifier(username, challengeParameters, clientMetadata, session, authenticationHelper, { userPoolId, userPoolClientId }, tokenOrchestrator) {
    const deviceMetadata = await tokenOrchestrator?.getDeviceMetadata(username);
    (0, types_1.assertDeviceMetadata)(deviceMetadata);
    const serverBValue = new BigInteger_1.BigInteger(challengeParameters?.SRP_B, 16);
    const salt = new BigInteger_1.BigInteger(challengeParameters?.SALT, 16);
    const deviceKey = deviceMetadata.deviceKey;
    const deviceGroupKey = deviceMetadata.deviceGroupKey;
    const hkdf = await authenticationHelper.getPasswordAuthenticationKey({
        username: deviceMetadata.deviceKey,
        password: deviceMetadata.randomPassword,
        serverBValue,
        salt,
    });
    const dateNow = (0, srp_1.getNowString)();
    const challengeResponses = {
        USERNAME: challengeParameters?.USERNAME ?? username,
        PASSWORD_CLAIM_SECRET_BLOCK: challengeParameters?.SECRET_BLOCK,
        TIMESTAMP: dateNow,
        PASSWORD_CLAIM_SIGNATURE: (0, srp_1.getSignatureString)({
            username: deviceKey,
            userPoolName: deviceGroupKey,
            challengeParameters,
            dateNow,
            hkdf,
        }),
        DEVICE_KEY: deviceKey,
    };
    const UserContextData = (0, userContextData_1.getUserContextData)({
        username,
        userPoolId,
        userPoolClientId,
    });
    const jsonReqResponseChallenge = {
        ChallengeName: 'DEVICE_PASSWORD_VERIFIER',
        ClientId: userPoolClientId,
        ChallengeResponses: challengeResponses,
        Session: session,
        ClientMetadata: clientMetadata,
        UserContextData,
    };
    return (0, CognitoIdentityProvider_1.respondToAuthChallenge)({ region: (0, utils_2.getRegion)(userPoolId) }, jsonReqResponseChallenge);
}
async function handlePasswordVerifierChallenge(password, challengeParameters, clientMetadata, session, authenticationHelper, config, tokenOrchestrator) {
    const { userPoolId, userPoolClientId } = config;
    const userPoolName = userPoolId?.split('_')[1] || '';
    const serverBValue = new BigInteger_1.BigInteger(challengeParameters?.SRP_B, 16);
    const salt = new BigInteger_1.BigInteger(challengeParameters?.SALT, 16);
    const username = challengeParameters?.USER_ID_FOR_SRP;
    if (!username)
        throw new AuthError_1.AuthError({
            name: 'EmptyUserIdForSRPException',
            message: 'USER_ID_FOR_SRP was not found in challengeParameters',
        });
    const hkdf = await authenticationHelper.getPasswordAuthenticationKey({
        username,
        password,
        serverBValue,
        salt,
    });
    const dateNow = (0, srp_1.getNowString)();
    const challengeResponses = {
        USERNAME: username,
        PASSWORD_CLAIM_SECRET_BLOCK: challengeParameters?.SECRET_BLOCK,
        TIMESTAMP: dateNow,
        PASSWORD_CLAIM_SIGNATURE: (0, srp_1.getSignatureString)({
            username,
            userPoolName,
            challengeParameters,
            dateNow,
            hkdf,
        }),
    };
    const deviceMetadata = await tokenOrchestrator.getDeviceMetadata(username);
    if (deviceMetadata && deviceMetadata.deviceKey) {
        challengeResponses['DEVICE_KEY'] = deviceMetadata.deviceKey;
    }
    const UserContextData = (0, userContextData_1.getUserContextData)({
        username,
        userPoolId,
        userPoolClientId,
    });
    const jsonReqResponseChallenge = {
        ChallengeName: 'PASSWORD_VERIFIER',
        ChallengeResponses: challengeResponses,
        ClientMetadata: clientMetadata,
        Session: session,
        ClientId: userPoolClientId,
        UserContextData,
    };
    const response = await (0, CognitoIdentityProvider_1.respondToAuthChallenge)({ region: (0, utils_2.getRegion)(userPoolId) }, jsonReqResponseChallenge);
    if (response.ChallengeName === 'DEVICE_SRP_AUTH')
        return handleDeviceSRPAuth({
            username,
            config,
            clientMetadata,
            session: response.Session,
            tokenOrchestrator,
        });
    return response;
}
exports.handlePasswordVerifierChallenge = handlePasswordVerifierChallenge;
async function getSignInResult(params) {
    const { challengeName, challengeParameters } = params;
    const authConfig = core_1.Amplify.getConfig().Auth?.Cognito;
    (0, utils_1.assertTokenProviderConfig)(authConfig);
    switch (challengeName) {
        case 'CUSTOM_CHALLENGE':
            return {
                isSignedIn: false,
                nextStep: {
                    signInStep: 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE',
                    additionalInfo: challengeParameters,
                },
            };
        case 'MFA_SETUP':
            const { signInSession, username } = signInStore_1.signInStore.getState();
            if (!isMFATypeEnabled(challengeParameters, 'TOTP'))
                throw new AuthError_1.AuthError({
                    name: AuthErrorStrings_1.AuthErrorCodes.SignInException,
                    message: `Cannot initiate MFA setup from available types: ${getMFATypes(parseMFATypes(challengeParameters.MFAS_CAN_SETUP))}`,
                });
            const { Session, SecretCode: secretCode } = await (0, CognitoIdentityProvider_1.associateSoftwareToken)({ region: (0, utils_2.getRegion)(authConfig.userPoolId) }, {
                Session: signInSession,
            });
            signInStore_1.signInStore.dispatch({
                type: 'SET_SIGN_IN_SESSION',
                value: Session,
            });
            return {
                isSignedIn: false,
                nextStep: {
                    signInStep: 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP',
                    totpSetupDetails: getTOTPSetupDetails(secretCode, username),
                },
            };
        case 'NEW_PASSWORD_REQUIRED':
            return {
                isSignedIn: false,
                nextStep: {
                    signInStep: 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
                    missingAttributes: parseAttributes(challengeParameters.requiredAttributes),
                },
            };
        case 'SELECT_MFA_TYPE':
            return {
                isSignedIn: false,
                nextStep: {
                    signInStep: 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION',
                    allowedMFATypes: getMFATypes(parseMFATypes(challengeParameters.MFAS_CAN_CHOOSE)),
                },
            };
        case 'SMS_MFA':
            return {
                isSignedIn: false,
                nextStep: {
                    signInStep: 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
                    codeDeliveryDetails: {
                        deliveryMedium: challengeParameters.CODE_DELIVERY_DELIVERY_MEDIUM,
                        destination: challengeParameters.CODE_DELIVERY_DESTINATION,
                    },
                },
            };
        case 'SOFTWARE_TOKEN_MFA':
            return {
                isSignedIn: false,
                nextStep: {
                    signInStep: 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
                },
            };
        case 'ADMIN_NO_SRP_AUTH':
            break;
        case 'DEVICE_PASSWORD_VERIFIER':
            break;
        case 'DEVICE_SRP_AUTH':
            break;
        case 'PASSWORD_VERIFIER':
            break;
    }
    // TODO: remove this error message for production apps
    throw new AuthError_1.AuthError({
        name: AuthErrorStrings_1.AuthErrorCodes.SignInException,
        message: 'An error occurred during the sign in process. ' +
            `${challengeName} challengeName returned by the underlying service was not addressed.`,
    });
}
exports.getSignInResult = getSignInResult;
function getTOTPSetupDetails(secretCode, username) {
    return {
        sharedSecret: secretCode,
        getSetupUri: (appName, accountName) => {
            const totpUri = `otpauth://totp/${appName}:${accountName ?? username}?secret=${secretCode}&issuer=${appName}`;
            return new utils_1.AmplifyUrl(totpUri);
        },
    };
}
exports.getTOTPSetupDetails = getTOTPSetupDetails;
function getSignInResultFromError(errorName) {
    if (errorName === errors_1.InitiateAuthException.PasswordResetRequiredException) {
        return {
            isSignedIn: false,
            nextStep: { signInStep: 'RESET_PASSWORD' },
        };
    }
    else if (errorName === errors_1.InitiateAuthException.UserNotConfirmedException) {
        return {
            isSignedIn: false,
            nextStep: { signInStep: 'CONFIRM_SIGN_UP' },
        };
    }
}
exports.getSignInResultFromError = getSignInResultFromError;
function parseAttributes(attributes) {
    if (!attributes)
        return [];
    const parsedAttributes = JSON.parse(attributes).map(att => att.includes(USER_ATTRIBUTES) ? att.replace(USER_ATTRIBUTES, '') : att);
    return parsedAttributes;
}
exports.parseAttributes = parseAttributes;
function createAttributes(attributes) {
    if (!attributes)
        return {};
    const newAttributes = {};
    Object.entries(attributes).forEach(([key, value]) => {
        if (value)
            newAttributes[`${USER_ATTRIBUTES}${key}`] = value;
    });
    return newAttributes;
}
exports.createAttributes = createAttributes;
async function handleChallengeName(username, challengeName, session, challengeResponse, config, tokenOrchestrator, clientMetadata, options) {
    const userAttributes = options?.userAttributes;
    const deviceName = options?.friendlyDeviceName;
    switch (challengeName) {
        case 'SMS_MFA':
            return handleSMSMFAChallenge({
                challengeResponse,
                clientMetadata,
                session,
                username,
                config,
            });
        case 'SELECT_MFA_TYPE':
            return handleSelectMFATypeChallenge({
                challengeResponse,
                clientMetadata,
                session,
                username,
                config,
            });
        case 'MFA_SETUP':
            return handleMFASetupChallenge({
                challengeResponse,
                clientMetadata,
                session,
                username,
                deviceName,
                config,
            });
        case 'NEW_PASSWORD_REQUIRED':
            return handleCompleteNewPasswordChallenge({
                challengeResponse,
                clientMetadata,
                session,
                username,
                requiredAttributes: userAttributes,
                config,
            });
        case 'CUSTOM_CHALLENGE':
            return retryOnResourceNotFoundException(handleCustomChallenge, [
                {
                    challengeResponse,
                    clientMetadata,
                    session,
                    username,
                    config,
                    tokenOrchestrator,
                },
            ], username, tokenOrchestrator);
        case 'SOFTWARE_TOKEN_MFA':
            return handleSoftwareTokenMFAChallenge({
                challengeResponse,
                clientMetadata,
                session,
                username,
                config,
            });
    }
    // TODO: remove this error message for production apps
    throw new AuthError_1.AuthError({
        name: AuthErrorStrings_1.AuthErrorCodes.SignInException,
        message: `An error occurred during the sign in process. 
		${challengeName} challengeName returned by the underlying service was not addressed.`,
    });
}
exports.handleChallengeName = handleChallengeName;
function mapMfaType(mfa) {
    let mfaType = 'SMS_MFA';
    if (mfa === 'TOTP')
        mfaType = 'SOFTWARE_TOKEN_MFA';
    return mfaType;
}
exports.mapMfaType = mapMfaType;
function getMFAType(type) {
    if (type === 'SMS_MFA')
        return 'SMS';
    if (type === 'SOFTWARE_TOKEN_MFA')
        return 'TOTP';
    // TODO: log warning for unknown MFA type
}
exports.getMFAType = getMFAType;
function getMFATypes(types) {
    if (!types)
        return undefined;
    return types.map(getMFAType).filter(Boolean);
}
exports.getMFATypes = getMFATypes;
function parseMFATypes(mfa) {
    if (!mfa)
        return [];
    return JSON.parse(mfa);
}
exports.parseMFATypes = parseMFATypes;
function isMFATypeEnabled(challengeParams, mfaType) {
    const { MFAS_CAN_SETUP } = challengeParams;
    const mfaTypes = getMFATypes(parseMFATypes(MFAS_CAN_SETUP));
    if (!mfaTypes)
        return false;
    return mfaTypes.includes(mfaType);
}
exports.isMFATypeEnabled = isMFATypeEnabled;
async function assertUserNotAuthenticated() {
    let authUser;
    try {
        authUser = await (0, getCurrentUser_1.getCurrentUser)();
    }
    catch (error) { }
    if (authUser && authUser.userId && authUser.username) {
        throw new AuthError_1.AuthError({
            name: constants_1.USER_ALREADY_AUTHENTICATED_EXCEPTION,
            message: 'There is already a signed in user.',
            recoverySuggestion: 'Call signOut before calling signIn again.',
        });
    }
}
exports.assertUserNotAuthenticated = assertUserNotAuthenticated;
/**
 * This function is used to kick off the device management flow.
 *
 * If an error is thrown while generating a hash device or calling the `ConfirmDevice`
 * client, then this API will ignore the error and return undefined. Otherwise the authentication
 * flow will not complete and the user won't be able to be signed in.
 *
 * @returns DeviceMetadata | undefined
 */
async function getNewDeviceMetatada(userPoolId, newDeviceMetadata, accessToken) {
    if (!newDeviceMetadata)
        return undefined;
    const userPoolName = userPoolId.split('_')[1] || '';
    const authenticationHelper = await (0, srp_1.getAuthenticationHelper)(userPoolName);
    const deviceKey = newDeviceMetadata?.DeviceKey;
    const deviceGroupKey = newDeviceMetadata?.DeviceGroupKey;
    try {
        await authenticationHelper.generateHashDevice(deviceGroupKey ?? '', deviceKey ?? '');
    }
    catch (errGenHash) {
        // TODO: log error here
        return undefined;
    }
    const deviceSecretVerifierConfig = {
        Salt: utils_1.base64Encoder.convert((0, srp_1.getBytesFromHex)(authenticationHelper.getSaltToHashDevices())),
        PasswordVerifier: utils_1.base64Encoder.convert((0, srp_1.getBytesFromHex)(authenticationHelper.getVerifierDevices())),
    };
    const randomPassword = authenticationHelper.getRandomPassword();
    try {
        await (0, CognitoIdentityProvider_1.confirmDevice)({ region: (0, utils_2.getRegion)(userPoolId) }, {
            AccessToken: accessToken,
            DeviceKey: newDeviceMetadata?.DeviceKey,
            DeviceSecretVerifierConfig: deviceSecretVerifierConfig,
        });
        return {
            deviceKey,
            deviceGroupKey,
            randomPassword,
        };
    }
    catch (error) {
        // TODO: log error here
        return undefined;
    }
}
exports.getNewDeviceMetatada = getNewDeviceMetatada;
/**
 * It will retry the function if the error is a `ResourceNotFoundException` and
 * will clean the device keys stored in the storage mechanism.
 *
 */
async function retryOnResourceNotFoundException(func, args, username, tokenOrchestrator) {
    try {
        return await func(...args);
    }
    catch (error) {
        if (error instanceof AuthError_1.AuthError &&
            error.name === 'ResourceNotFoundException' &&
            error.message.includes('Device does not exist.')) {
            await tokenOrchestrator.clearDeviceMetadata(username);
            return await func(...args);
        }
        throw error;
    }
}
exports.retryOnResourceNotFoundException = retryOnResourceNotFoundException;
function setActiveSignInUsername(username) {
    const { dispatch } = signInStore_1.signInStore;
    dispatch({ type: 'SET_USERNAME', value: username });
}
exports.setActiveSignInUsername = setActiveSignInUsername;
function getActiveSignInUsername(username) {
    const state = signInStore_1.signInStore.getState();
    return state.username ?? username;
}
exports.getActiveSignInUsername = getActiveSignInUsername;
