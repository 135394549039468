"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUpTOTP = void 0;
const core_1 = require("@aws-amplify/core");
const utils_1 = require("@aws-amplify/core/internals/utils");
const AuthError_1 = require("../../../errors/AuthError");
const errors_1 = require("../types/errors");
const signInHelpers_1 = require("../utils/signInHelpers");
const CognitoIdentityProvider_1 = require("../utils/clients/CognitoIdentityProvider");
const utils_2 = require("../utils/clients/CognitoIdentityProvider/utils");
const types_1 = require("../utils/types");
const utils_3 = require("../../../utils");
/**
 * Sets up TOTP for the user.
 *
 * @returns SetUpTOTPOutput
 * @throws -{@link AssociateSoftwareTokenException}
 * Thrown if a service occurs while setting up TOTP.
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 **/
async function setUpTOTP() {
    const authConfig = core_1.Amplify.getConfig().Auth?.Cognito;
    (0, utils_1.assertTokenProviderConfig)(authConfig);
    const { tokens } = await (0, core_1.fetchAuthSession)({ forceRefresh: false });
    (0, types_1.assertAuthTokens)(tokens);
    const username = tokens.idToken?.payload['cognito:username'] ?? '';
    const { SecretCode } = await (0, CognitoIdentityProvider_1.associateSoftwareToken)({
        region: (0, utils_2.getRegion)(authConfig.userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.SetUpTOTP),
    }, {
        AccessToken: tokens.accessToken.toString(),
    });
    if (!SecretCode) {
        // This should never happen.
        throw new AuthError_1.AuthError({
            name: errors_1.SETUP_TOTP_EXCEPTION,
            message: 'Failed to set up TOTP.',
        });
    }
    return (0, signInHelpers_1.getTOTPSetupDetails)(SecretCode, JSON.stringify(username));
}
exports.setUpTOTP = setUpTOTP;
