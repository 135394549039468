"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateEndpoint = void 0;
const amplifyUuid_1 = require("../../../utils/amplifyUuid");
const getClientInfo_1 = require("../../../utils/getClientInfo");
const pinpoint_1 = require("../../../awsClients/pinpoint");
const cacheEndpointId_1 = require("../utils/cacheEndpointId");
const getEndpointId_1 = require("../utils/getEndpointId");
/**
 * @internal
 */
const updateEndpoint = async ({ address, appId, category, channelType, credentials, identityId, optOut, region, userAttributes, userId, userProfile, userAgentValue, }) => {
    const endpointId = await (0, getEndpointId_1.getEndpointId)(appId, category);
    // only generate a new endpoint id if one was not found in cache
    const createdEndpointId = !endpointId ? (0, amplifyUuid_1.amplifyUuid)() : undefined;
    const { customProperties, demographic, email, location, metrics, name, plan, } = userProfile ?? {};
    const clientInfo = (0, getClientInfo_1.getClientInfo)();
    const mergedDemographic = {
        appVersion: clientInfo.appVersion,
        make: clientInfo.make,
        model: clientInfo.model,
        modelVersion: clientInfo.version,
        platform: clientInfo.platform,
        ...demographic,
    };
    const shouldAddAttributes = email || customProperties || name || plan;
    const attributes = {
        ...(email && { email: [email] }),
        ...(name && { name: [name] }),
        ...(plan && { plan: [plan] }),
        ...customProperties,
    };
    const input = {
        ApplicationId: appId,
        EndpointId: endpointId ?? createdEndpointId,
        EndpointRequest: {
            RequestId: (0, amplifyUuid_1.amplifyUuid)(),
            EffectiveDate: new Date().toISOString(),
            ChannelType: channelType,
            Address: address,
            Attributes: shouldAddAttributes ? attributes : undefined,
            Demographic: {
                AppVersion: mergedDemographic.appVersion,
                Locale: mergedDemographic.locale,
                Make: mergedDemographic.make,
                Model: mergedDemographic.model,
                ModelVersion: mergedDemographic.modelVersion,
                Platform: mergedDemographic.platform,
                PlatformVersion: mergedDemographic.platformVersion,
                Timezone: mergedDemographic.timezone,
            },
            Location: {
                City: location?.city,
                Country: location?.country,
                Latitude: location?.latitude,
                Longitude: location?.longitude,
                PostalCode: location?.postalCode,
                Region: location?.region,
            },
            Metrics: metrics,
            OptOut: optOut,
            User: {
                UserId: userId ?? identityId,
                UserAttributes: userAttributes,
            },
        },
    };
    await (0, pinpoint_1.updateEndpoint)({ credentials, region, userAgentValue }, input);
    // if we had to create an endpoint id, we need to now cache it
    if (!!createdEndpointId) {
        return (0, cacheEndpointId_1.cacheEndpointId)(appId, category, createdEndpointId);
    }
};
exports.updateEndpoint = updateEndpoint;
