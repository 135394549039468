"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.OAUTH_SIGNOUT_EXCEPTION = exports.invalidOriginException = exports.INVALID_ORIGIN_EXCEPTION = exports.invalidRedirectException = exports.INVALID_REDIRECT_EXCEPTION = exports.AUTO_SIGN_IN_EXCEPTION = exports.DEVICE_METADATA_NOT_FOUND_EXCEPTION = exports.USER_ALREADY_AUTHENTICATED_EXCEPTION = exports.USER_UNAUTHENTICATED_EXCEPTION = void 0;
const AuthError_1 = require("./AuthError");
exports.USER_UNAUTHENTICATED_EXCEPTION = 'UserUnAuthenticatedException';
exports.USER_ALREADY_AUTHENTICATED_EXCEPTION = 'UserAlreadyAuthenticatedException';
exports.DEVICE_METADATA_NOT_FOUND_EXCEPTION = 'DeviceMetadataNotFoundException';
exports.AUTO_SIGN_IN_EXCEPTION = 'AutoSignInException';
exports.INVALID_REDIRECT_EXCEPTION = 'InvalidRedirectException';
exports.invalidRedirectException = new AuthError_1.AuthError({
    name: exports.INVALID_REDIRECT_EXCEPTION,
    message: 'signInRedirect or signOutRedirect had an invalid format or was not found.',
    recoverySuggestion: 'Please make sure the signIn/Out redirect in your oauth config is valid.',
});
exports.INVALID_ORIGIN_EXCEPTION = 'InvalidOriginException';
exports.invalidOriginException = new AuthError_1.AuthError({
    name: exports.INVALID_ORIGIN_EXCEPTION,
    message: 'redirect is coming from a different origin. The oauth flow needs to be initiated from the same origin',
    recoverySuggestion: 'Please call signInWithRedirect from the same origin.',
});
exports.OAUTH_SIGNOUT_EXCEPTION = 'OAuthSignOutException';
