"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.getId = void 0;
const base_1 = require("./base");
const clients_1 = require("../../clients");
const internal_1 = require("../../clients/internal");
const getIdSerializer = (input, endpoint) => {
    const headers = (0, base_1.getSharedHeaders)('GetId');
    const body = JSON.stringify(input);
    return (0, base_1.buildHttpRpcRequest)(endpoint, headers, body);
};
const getIdDeserializer = async (response) => {
    if (response.statusCode >= 300) {
        const error = await (0, clients_1.parseJsonError)(response);
        throw error;
    }
    else {
        const body = await (0, clients_1.parseJsonBody)(response);
        return {
            IdentityId: body.IdentityId,
            $metadata: (0, clients_1.parseMetadata)(response),
        };
    }
};
/**
 * @internal
 */
exports.getId = (0, internal_1.composeServiceApi)(base_1.cognitoIdentityTransferHandler, getIdSerializer, getIdDeserializer, base_1.defaultConfig);
